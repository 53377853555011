class UserMedia {
    constructor($controlParent, constraints) {
        this.$controlParent = $controlParent;
        this.constraints = constraints;

        this.permissionPromise = null;
        this.permissionResolver = null;
        this.permissionRejector = null;

        this.rendered = false;

        this.onPermissionButtonClick = this.onPermissionButtonClick.bind(this);
    }

    getStream() {
        return navigator.mediaDevices
            .getUserMedia(this.constraints)
            .then((stream) => stream)
            .catch((e) => {
                console.error('navigator.mediaDevices.getUserMedia error:', e);
                throw e;
            });
    }

    requestPermission() {
        this.render();
        this.startPermissionPromise();
        return this.permissionPromise;
    }

    onPermissionButtonClick() {
        this.getStream()
            .then((stream) => {
                let permissionResolver = this.permissionResolver;

                this.clearPromise();
                this.teardownRender();
                permissionResolver(stream);
            })
            .catch((e) => {
                console.error('permission error:', e);
            });
    }

    startPermissionPromise() {
        this.permissionPromise = new Promise((resolve, reject) => {
            this.permissionResolver = resolve;
            this.permissionRejector = reject;
        });
    }

    clearPermissionPromise() {
        this.permissionRejector = null;
        this.permissionResolver = null;
        this.permissionPromise = null;
    }

    render() {
        let $controlParent = this.$controlParent;

        this.rendered && this.teardownRender();

        $controlParent.innerHTML = `
            <div id="permissionRequest">
                <h1>Permission required:</h1>
                <p>Access to the camera and microphone is required to record video.</p>
                <button class="green" id="request-button">Allow Access</button>
            </div>
        `;

        this.$permissionButton =
            $controlParent.querySelector('#request-button');
        this.$permissionButton.onclick = this.onPermissionButtonClick;

        this.rendered = true;
    }

    teardownRender() {
        this.$controlParent.innerHTML = '';
        this.$permissionButton.onclick = null;
        this.$permissionButton = null;

        this.rendered = false;
    }
}

export default UserMedia;
